export default [
    {
        name: 'error',
        path: '/error',
        component: ( ) => import('@/views/error/ErrorView.vue')
    },

    {
        name: 'error-maintenance-mode',
        path: '/maintenance-mode',
        component: ( ) => import('@/views/error/MaintenanceModeView.vue')
    }
]