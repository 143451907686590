//const axios = require("axios")
//import * as constants from "@/store/constants.js"
//import { mutations, state } from "@/store"
//import { sendRequest } from "@/lib/gms/api"
//import storage from "@/lib/gms/storage"
//import { deepCopy } from "@/lib/gms/utils"
//import ability from "@/lib/acl/ability"

/**
 * Definice jednotlivých chyb.
 */
export const ERROR_SERVICE_TEMPORARILY_UNAVAILABLE = {
    id: "ServiceTemporarilyUnavailable",
    message: "Služba dočasně nedostupná.",
    code: 503
}

export const ERROR_VALIDATION = {
    id: "Validation",
    message: "Chybný parametr.",
    code: 422
}

export const ERROR_WRONG_USERNAME_OR_PASSWORD = {
    id: "WrongUsernameOrPassword",
    message: "Neplatné uživatelské jméno nebo heslo.",
    code: 404
}

export const defaultsError = { }

export const stateError = { }

export const mutationsError = {
    /**
     * Zpracuje chybu.
     * 
     * @param   instance Vue    vm
     * @param   ErrorBody       error
     */
    processError(vm, error) {
        // Test na chybu "ServiceTemporarilyUnavailable"
        if (error.id == ERROR_SERVICE_TEMPORARILY_UNAVAILABLE["id"]) {
            //console.log("ServiceTemporarilyUnavailable")
            vm.$router.push({ name: "error-maintenance-mode", params: {  } })
            return
        }

        // Test na chybu "Validation"
        if (error.id == ERROR_VALIDATION["id"]) {
            //console.log("Validation")
            return
        }

        // Výchozí chování
        vm.$bvToast.toast(error.message, {
            autoHideDelay: 3000,
            appendToast: false,
            solid: true,
            title: vm.$t('Error'),
            toaster: 'b-toaster-top-center',
            variant: "danger"
        })
    },

    /**
     * Zpracuje urgentní chybu.
     * 
     * @param   instance Vue    vm
     * @param   ErrorBody       error
     */
    processUrgentError(vm, error) {
        console.log("processUrgentError( )", error)
        vm.$router.push('/error')
    },

    /**
     * Zpracuje upozornění.
     * 
     * @param   instance Vue    vm
     * @param   ErrorBody       error
     */
    processNotice(vm, error) {
        // Výchozí chování
        vm.$bvToast.toast(error.message, {
            autoHideDelay: 1000,
            appendToast: false,
            solid: true,
            title: vm.$t('Success'),
            toaster: 'b-toaster-top-center',
            variant: "success"
        })
    },

    /**
     * Zpracuje varování.
     * 
     * @param   instance Vue    vm
     * @param   ErrorBody       error
     */
    processWarning(vm, error) {
        // Výchozí chování
        vm.$bvToast.toast(error.message, {
            autoHideDelay: 2000,
            appendToast: false,
            solid: true,
            title: vm.$t('Warning'),
            toaster: 'b-toaster-top-center',
            variant: "warning"
        })
    }
}