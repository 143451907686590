/**
 * This file is part of the GMS package.
 * 
 * @version 1.1
 */

import { isJSON } from './utils'

/**
 * Management úložiště.
 */
export default class {
    /**
     * Získá entitu z (lokálního) úložiště.
     * 
     * @param {string}  itemName
     */
     static getItem(itemName) {
        let itemData = localStorage.getItem(itemName)

        if (itemData == null) return itemData

        // Deserializace vybraných datových typů
        if (isJSON(itemData)) {
            itemData = JSON.parse(itemData)
        }

        return itemData
    }

    /**
     * Odstraní entitu z (lokálního) úložiště.
     * 
     * @param {string}  itemName
     */
     static removeItem(itemName) {
        localStorage.removeItem(itemName)
    }
    
    /**
     * Uloží entitu do (lokálního) úložiště.
     * 
     * @param {string}  itemName
     * @param {*}       itemData
     */
    static setItem(itemName, itemData) {
        // Serializace vybraných datových typů
        if (typeof itemData === 'object') {
            itemData = JSON.stringify(itemData)
        }

        localStorage.setItem(itemName, itemData)
    }
}